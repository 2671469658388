import { useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, notification, Row } from 'antd';
import Lottie from 'react-lottie-player';

import { plantApi } from '@/api';
import loadingLottie from '@/assets/lotties/ratio-loading-lottie.json';
import Card from '@/components/RCard';
import RNotificationCard from '@/components/RNotificationCard';
import Steps from '@/components/RSteps';
import { AnimatedText } from '@/components/RTextAnimation';
import RUsageLimitIndicator from '@/components/RUsageLimitIndicator';
import { Draft } from '@/types/global';
import { PlantForm } from '@/types/plant';
import { dateStringToISO8601, handleError } from '@/utils';

import { StyledDrawer } from './styles';
import Description from '../Form/Description';
import FinancialMetrics from '../Form/FinancialMetrics';
import GenerationProfile from '../Form/GenerationProfile';
import GridActions from '../Form/GridActions';
import MechanicalProps from '../Form/MechanicalProps';
import ParticipationFiT from '../Form/ParticipationFiT';

type PlantFormKeys = keyof PlantForm;

type Props = {
  open: boolean;
  setIsOpen: (value: boolean) => void;
  mutate: () => void;
  maxDataCount: number;
  currentDataCount: number;
};

const PlantCreateDrawer = (props: Props) => {
  //HOOK
  const { open, setIsOpen, mutate, maxDataCount, currentDataCount } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [notificationCardVisible, setNotificationCardVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const initialValues: PlantForm = {
    capexUnit: 'dollars',
    investmentLag: 0,
  };
  const [draft, setDraft] = useState<Draft>({
    draftId: undefined,
    startDate: undefined,
    endDate: undefined,
  });
  const [uploadError, setUploadError] = useState<string | null>('');

  const hasUnsavedData = () => {
    const fields: Partial<PlantForm> = form.getFieldsValue(true);

    return (Object.keys(fields) as PlantFormKeys[]).some(
      (key) => fields[key] !== initialValues[key]
    );
  };

  const onClose = () => {
    if (hasUnsavedData()) {
      setNotificationCardVisible(true);
    } else {
      setIsOpen(false);
    }
  };

  const resetDrawerData = () => {
    setCurrentStep(0);
    form.resetFields();
  };

  const steps = [
    {
      key: 'first',
      title: 'Description',
      content: <Description />,
    },
    {
      key: 'second',
      title: 'Mechanical Properties',
      content: <MechanicalProps />,
    },
    {
      key: 'third',
      title: 'Grid Actions',
      content: <GridActions />,
    },
    {
      key: 'fourth',
      title: 'Participation in FiT',
      content: <ParticipationFiT form={form} />,
    },
    {
      key: 'fifth',
      title: 'Financial Metrics',
      content: <FinancialMetrics form={form} />,
    },
    {
      key: 'sixth',
      title: 'Generation Profile',
      content: (
        <GenerationProfile
          form={form}
          draft={draft}
          setDraft={setDraft}
          uploadError={uploadError}
          setUploadError={setUploadError}
        />
      ),
    },
  ];

  const nextStep = async () => {
    if (currentStep < steps.length - 1) {
      try {
        const values = await form.validateFields();
        form.setFieldsValue(values);
        setCurrentStep((prev) => prev + 1);
      } catch (error) {
        console.error('Validation failed:', error);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const onFinish = async () => {
    const values: PlantForm = form.getFieldsValue(true);
    if (
      !values.name ||
      !values.generationType ||
      !values.installedPowerKw ||
      !values.licensedPowerKw ||
      !values.fixedOpex ||
      !values.capexValue ||
      !values.capexUnit ||
      !values.depreciationYears ||
      !values.generation ||
      !values.gridPowerConstraintInflow ||
      !values.gridPowerConstraintOutflow
    ) {
      throw new Error('Some required values are undefined.');
    }
    try {
      setIsLoading(true);

      await plantApi.createPlant({
        draftId: draft.draftId,
        name: values.name,
        description: values.description,
        capexValue: values.capexValue,
        capexUnit: values.capexUnit,
        fixedOpex: values.fixedOpex,
        installedPowerKw: values.installedPowerKw,
        licensedPowerKw: values.licensedPowerKw,
        generationFeedInTariff: values.generationFeedInTariff,
        generationFeedInTariffEndDate:
          values.generationFeedInTariffEndDate &&
          dateStringToISO8601(values.generationFeedInTariffEndDate as string),
        generationLocalContributionAmount:
          values.generationLocalContributionAmount,
        generationLocalContributionEndDate:
          values.generationLocalContributionEndDate &&
          dateStringToISO8601(
            values.generationLocalContributionEndDate as string
          ),
        generationFeedInTariffWithStorage:
          values.generationFeedInTariffWithStorage,
        generationFeedInTariffWithStorageEndDate:
          values.generationFeedInTariffWithStorageEndDate &&
          dateStringToISO8601(
            values.generationFeedInTariffWithStorageEndDate as string
          ),
        storageLocalContributionAmount: values.storageLocalContributionAmount,
        storageLocalContributionEndDate:
          values.storageLocalContributionEndDate &&
          dateStringToISO8601(values.storageLocalContributionEndDate as string),
        generationType: values.generationType,
        depreciationYears: values.depreciationYears,
        gridPowerConstraintOutflow: values.gridPowerConstraintOutflow,
        distributionFeePerMwh: values.distributionFeePerMwh,
        gridPowerConstraintInflow: values.gridPowerConstraintInflow,
        withdrawalFeePerMwh: values.withdrawalFeePerMwh,
        lifespan: values.lifespan,
        investmentLag: values.investmentLag,
        preopCapexDistribution: values.preopCapexDistribution,
        loanFinancing: values.loanFinancing,
        loanFundedCapexRatio: values.loanFundedCapexRatio,
        loanRepaymentPeriod: values.loanRepaymentPeriod,
        yearlyInterestExpense: values.yearlyInterestExpense,
      });

      notification.success({
        message: `${values.name} plant has been created successfully!`,
      });

      setIsLoading(false);
      resetDrawerData();
      mutate();
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  //TODO Split this into a separate component
  const overlayTextArray = useMemo(() => {
    return [
      'Configuring plant settings.',
      'Analyzing file...',
      'The final touches are being made.',
    ];
  }, []);

  const [overlayText, setOverlayText] = useState<string>(overlayTextArray[0]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        if (currentIndex < overlayTextArray.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setOverlayText(overlayTextArray[currentIndex + 1]);
        } else {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, overlayTextArray, isLoading]);

  return (
    <StyledDrawer
      title="Create New Plant"
      description="Add a new plant to the library by providing its details below."
      destroyOnClose
      onClose={onClose}
      open={open}
      rightSideSection={<RUsageLimitIndicator.Plants />}
      drawerLock={{
        isLocked: !!(currentDataCount === maxDataCount),
        lockText: 'You have reached the limit of plants you can create.',
      }}
    >
      <Card $borderColor="transparent" className="px-16 pt-16">
        <Form
          className="form-styles"
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={initialValues}
        >
          <Steps
            className="mb-24"
            current={currentStep}
            items={steps}
            type="default"
          />
          <div>{steps[currentStep].content}</div>
          <Row
            className="bottom-button-group"
            align="middle"
            justify="space-between"
          >
            <Button disabled={isLoading} onClick={onClose} className="mr-8">
              Cancel
            </Button>
            <div className="right-side-btn-group">
              {currentStep > 0 && (
                <Button
                  disabled={isLoading}
                  className="mr-4"
                  onClick={prevStep}
                >
                  Previous
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button
                  disabled={isLoading}
                  className="ml-4"
                  onClick={nextStep}
                  type="primary"
                >
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  className="ml-4"
                  type="primary"
                  htmlType="submit"
                >
                  Create
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </Card>
      <RNotificationCard
        title="Data will be lost."
        message="Are you sure you want to leave?"
        type="warning"
        confirmText="Leave"
        cancelText="Cancel"
        open={notificationCardVisible}
        onConfirm={() => {
          resetDrawerData();
          setNotificationCardVisible(false);
          setIsOpen(false);
        }}
        onCancel={() => setNotificationCardVisible(false)}
      />
      {isLoading && (
        <Row align="top" justify="center" className="pt-120 loading-wrapper">
          <Col>
            <Lottie
              style={{ height: '120px' }}
              animationData={loadingLottie}
              loop={true}
              play
            />
            <Row>
              <AnimatedText className="animated-text" text={overlayText} />
            </Row>
          </Col>
        </Row>
      )}
    </StyledDrawer>
  );
};

export default PlantCreateDrawer;

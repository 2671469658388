import { useState } from 'react';

import { Typography } from 'antd';
import { TableColumnsType } from 'antd/lib';
import { useParams } from 'react-router';
import useSWR from 'swr';

import IconDown from '@/assets/icons/icon-down.svg?react';
import IconRight from '@/assets/icons/icon-right.svg?react';
import Table from '@/components/RTable';
import {
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
  SimulationResultYearlyFinancialAnalysisData,
} from '@/types/simulations';
import { converToMillion } from '@/utils';

import StyledProfit from './styles';

interface DataType {
  key: string;
  name: string | JSX.Element;
  total: string;
  [key: string]: string | number | JSX.Element | undefined;
}

const PNLStorageTable = () => {
  const resultId = useParams<Record<string, string>>();
  const { data: financialData } =
    useSWR<SimulationResultYearlyFinancialAnalysis>(
      `/simulation/${resultId.resultId}/yearly_financial_analysis/`
    );

  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onExpand = (expanded: boolean, record: any) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customExpandIcon = (props: any) => {
    if (!props.record.children) {
      return <span style={{ marginRight: '22px' }} />;
    }
    if (props.expanded) {
      return (
        <IconDown
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    } else {
      return (
        <IconRight
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    }
  };

  let years: number[] = [];

  financialData?.graphData.forEach((item) => {
    years.push(item.year);
  });

  const scrollX = 775 + years.length * 100;

  years = [...new Set(years)].sort((a, b) => a - b);

  if (!financialData || !simulationData) {
    return null;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 350,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
    ...years.map((year) => ({
      title: year.toString(),
      dataIndex: year.toString(),
      key: year.toString(),
      width: 100,
    })),
  ];

  const getValuesForYears = (
    graphData: SimulationResultYearlyFinancialAnalysisData[],
    key: string
  ) => {
    const values: Record<string, string | number> = {};
    if (graphData) {
      years.forEach((year) => {
        const data = graphData.find((item) => item.year === year);
        values[year.toString()] = data
          ? converToMillion(data[key]) + ' $'
          : '0';
      });
    }
    return values;
  };

  financialData.graphData.forEach((item) => {
    const totalDepreciation = item.storageDepreciation + item.plantDepreciation;
    item.totalDepreciation = totalDepreciation;
  });

  const dataSource = [
    {
      key: '1',
      name: (
        <Typography.Text className="fs-14-bold text-gray-color">
          Revenues Battery
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.revenueBattery) + ' $',
      ...getValuesForYears(financialData.graphData, 'revenueBattery'),
      children: [
        {
          key: '1-1',
          name: 'Additional Revenue From Arbitrage',
          total:
            converToMillion(
              simulationData?.result.additionalRevenueFromArbitrage
            ) + ' $',
          ...getValuesForYears(
            financialData.graphData,
            'additionalRevenueFromArbitrage'
          ),
        },
        {
          key: '1-2',
          name: 'Revenue From Offered Capacity',
          total:
            converToMillion(simulationData?.result.revenueFromOfferedCapacity) +
            ' $',
          ...getValuesForYears(
            financialData.graphData,
            'revenueFromOfferedCapacity'
          ),
        },
      ],
    },
    {
      key: '2',
      name: 'Additional Cost of Storage',
      total:
        converToMillion(simulationData?.result.additionalCostOfStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'additionalCostOfStorage'),
    },
    {
      key: '3',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Gross Profit of Storage
        </Typography.Text>
      ),
      total:
        converToMillion(simulationData?.result.grossProfitOfStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'grossProfitOfStorage'),
    },
    {
      key: '4',
      name: 'Total Storage Opex',
      total: converToMillion(simulationData?.result.totalStorageOpex) + ' $',
      ...getValuesForYears(financialData.graphData, 'totalStorageOpex'),
    },
    {
      key: '5',
      name: 'EBITDA Storage',
      total: converToMillion(simulationData?.result.ebitdaStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'ebitdaStorage'),
    },
    {
      key: '6',
      name: 'Depreciation Storage',
      ...getValuesForYears(financialData.graphData, 'depreciationStorage'),
    },
    {
      key: '7',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          EBIT Storage
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.ebitStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'ebitStorage'),
    },
    {
      key: '8',
      name: 'Interest Expense Storage',
      total:
        converToMillion(simulationData?.result.interestExpenseStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'interestExpenseStorage'),
    },
    {
      key: '9',
      name: 'Taxes Storage',
      total: converToMillion(simulationData?.result.taxStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'taxStorage'),
    },
    {
      key: '10',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Net Income Storage
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.netIncomeStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'netIncomeStorage'),
    },
  ];

  return (
    <StyledProfit>
      <Table
        dataSource={dataSource}
        columns={columns}
        expandable={{
          expandedRowKeys,
          onExpand,
          expandIcon: customExpandIcon,
          rowExpandable: (record) => !!record.children,
        }}
        pagination={false}
        scroll={{ x: scrollX }}
      />
    </StyledProfit>
  );
};

export default PNLStorageTable;

import { FC, useEffect, useMemo, useState } from 'react';

import { Typography, Flex, Select, Row, Spin } from 'antd';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RToggleButtons from '@/components/RToggleButtons';
import RTooltip from '@/components/RTooltip';
import {
  SimulationResult,
  SimulationResultFinancialActivity,
} from '@/types/simulations';
import { getYears, insertLineBreak, formatValue } from '@/utils';

interface CustomLegendProp {
  payload: {
    color: string;
    value: string;
    payload: {
      value: number;
      percent: number;
    };
  }[];
}

const CustomLegend: FC<CustomLegendProp> = ({ payload }) => (
  <Flex vertical gap={16}>
    {payload?.map((entry, index) => (
      <Flex key={`item-${index}`} align="center" gap={8}>
        <div
          style={{
            width: '12px',
            height: '12px',
            backgroundColor: entry.color,
            borderRadius: '50%',
          }}
        ></div>
        <Flex vertical>
          <Typography.Text className="fs-14-medium text-gray-color">
            {insertLineBreak(entry.value ?? '', 4)}
          </Typography.Text>

          <div>
            <Typography.Text className="fs-14-bold text-gray-color">
              ${formatValue(entry.payload?.value ?? 0)}
            </Typography.Text>
            <Typography.Text className="fs-14-medium text-gray-color">
              {' '}
              {`(${((entry.payload?.percent ?? 0) * 100).toFixed(1)}%)`}
            </Typography.Text>
          </div>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Earnings = () => {
  const resultId = useParams<Record<string, string>>();
  const [storageSelection, setStorageSelection] = useState('withStorage');
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [switchButton, setSwitchButton] = useState<string>('All Years');
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const theme = useTheme();

  const handleStorageSelection = (value: string) => {
    setStorageSelection(value);
  };

  const years = useMemo(() => {
    return simulationData
      ? getYears(simulationData.startDate, simulationData.endDate)
      : [];
  }, [simulationData]);

  useEffect(() => {
    if (selectedYear !== undefined) {
      const yearIndex = years.findIndex((year) => year === selectedYear) + 1;
      setSwitchButton(`Year ${yearIndex}`);
    }
  }, [selectedYear, years]);

  const getEndpoint = () => {
    if (switchButton === 'All Years') {
      return `/simulation/${resultId.resultId}/financial_activity/`;
    } else if (selectedYear) {
      const startDate = `${selectedYear}-01-01T00:00:00Z`;
      const endDate = `${selectedYear}-12-31T23:59:59Z`;
      return `/simulation/${resultId.resultId}/financial_activity/?endDatetime=${endDate}&startDatetime=${startDate}&timeUnit=year`;
    }
    return null;
  };
  const endpoint = getEndpoint();
  const {
    data: financialActivityData,
    isLoading,
    error,
  } = useSWR<SimulationResultFinancialActivity>(endpoint, {
    revalidateOnFocus: false,
  });

  const getPieData = () => {
    if (!financialActivityData) return [];

    if (storageSelection === 'withoutStorage') {
      return [
        {
          name: 'Revenue of Directly Sold Energy from Plant',
          value: financialActivityData.graphData.reduce(
            (acc, item) => acc + (item.revenueWos ?? 0),
            0
          ),
          color: theme.colors.greenSecondary,
          unit: '$',
        },
      ];
    }

    if (storageSelection === 'storage') {
      return [
        {
          name: 'Revenue of Directly Sold Energy from Plant',
          value: financialActivityData.graphData.reduce(
            (acc, item) => acc + item.revenueDirectlySoldStorage,
            0
          ),
          color: theme.colors.greenSecondary,
          unit: '$',
        },
        {
          name: 'Revenue from Discharged Energy from Plant',
          value: financialActivityData.graphData.reduce(
            (acc, item) => acc + item.revenueFromChargedEnergyFromPlant,
            0
          ),
          color: theme.colors.orangeSecondary,
          unit: '$',
        },
        {
          name: 'Revenue from Arbitrage',
          value: financialActivityData.graphData.reduce(
            (acc, item) => acc + item.revenueFromArbitrage,
            0
          ),
          color: theme.colors.purpleSecondary,
          unit: '$',
        },
        {
          name: 'Revenue from Ancillary Services',
          value: financialActivityData.graphData.reduce(
            (acc, item) => acc + item.revenueFromAncillaryServices,
            0
          ),
          color: theme.colors.blueLight,
          unit: '$',
        },
      ];
    }

    // Default case (withStorage)
    return [
      {
        name: 'Revenue of Directly Sold Energy from Plant',
        value: financialActivityData.graphData.reduce(
          (acc, item) => acc + item.revenueFromPlant,
          0
        ),
        color: theme.colors.greenSecondary,
        unit: '$',
      },
      {
        name: 'Revenue from Discharged Energy from Plant',
        value: financialActivityData.graphData.reduce(
          (acc, item) => acc + item.revenueFromChargedEnergyFromPlant,
          0
        ),
        color: theme.colors.orangeSecondary,
        unit: '$',
      },
      {
        name: 'Revenue from Arbitrage',
        value: financialActivityData.graphData.reduce(
          (acc, item) => acc + item.revenueFromArbitrage,
          0
        ),
        color: theme.colors.purpleSecondary,
        unit: '$',
      },
      {
        name: 'Revenue from Ancillary Services',
        value: financialActivityData.graphData.reduce(
          (acc, item) => acc + item.revenueFromAncillaryServices,
          0
        ),
        color: theme.colors.blueLight,
        unit: '$',
      },
    ];
  };

  const getCostValue = () => {
    if (!financialActivityData?.graphData.length) return 0;

    if (storageSelection === 'withoutStorage') {
      return financialActivityData.graphData[0].cogsWos ?? 0;
    }
    if (storageSelection === 'storage') {
      return (
        financialActivityData.graphData[0].costOfPurchasedEnergyStorage ?? 0
      );
    }
    return financialActivityData.graphData[0].costOfPurchasedEnergy ?? 0;
  };

  const data = getPieData();

  const isDataZero =
    data.length === 0 || data.every((item) => item.value === 0);

  const handleSwitchButtonChange = (value: string) => {
    setSwitchButton(value);
    if (value === 'All Years') {
      setSelectedYear(undefined);
    } else if (value === 'Year 1' && years.length > 0) {
      setSelectedYear(years[0]);
    }
  };

  const handleYearChange = (value: number) => {
    setSelectedYear(value);
  };

  return (
    <Row className="w-100">
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Earnings Distribution
            </Typography.Title>
            <RTooltip
              title="Earnings Distribution"
              description="Earnings attributed to specific value stream"
            >
              <IconInfo />
            </RTooltip>
          </Flex>
        }
        extra={
          <Flex gap={16} align="center">
            <motion.div
              whileTap={{ scale: 1.1 }}
              transition={{ type: 'spring', stiffness: 300, damping: 24 }}
            >
              <Select
                placeholder="Storage Selection"
                style={{ minWidth: '150px', width: '150px' }}
                value={storageSelection}
                onChange={handleStorageSelection}
              >
                <Select.Option value="withStorage">With Storage</Select.Option>
                <Select.Option value="withoutStorage">
                  Without Storage
                </Select.Option>
                <Select.Option value="storage">Storage</Select.Option>
              </Select>
            </motion.div>
            <RToggleButtons
              labels={[
                `Year ${selectedYear ? years.indexOf(selectedYear) + 1 : 1}`,
                'All Years',
              ]}
              selectedLabel={switchButton}
              onLabelChange={handleSwitchButtonChange}
            />
            <motion.div
              whileTap={{ scale: 1.1 }}
              transition={{ type: 'spring', stiffness: 300, damping: 24 }}
            >
              <Select
                placeholder="Years"
                style={{ minWidth: '89px', width: '89px' }}
                value={selectedYear?.toString()}
                onChange={(value) => handleYearChange(Number(value))}
                disabled={switchButton === 'All Years'}
              >
                {years.map((year) => (
                  <Select.Option key={year} value={year}>
                    {year}
                  </Select.Option>
                ))}
              </Select>
            </motion.div>
          </Flex>
        }
        $paddingBody="32px 24px 0px"
        styles={{ header: { padding: '14px 24px' } }}
        style={{
          width: '100%',
          borderEndStartRadius: '0px',
          borderEndEndRadius: '0px',
          marginTop: '24px',
        }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: '332px' }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error || isDataZero ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 328 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            {error && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                An error occured while fetching data. Please check your inputs
                and contact support if the issue persists.
              </Typography.Text>
            )}
            {isDataZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            )}
          </Flex>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={255}>
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={125}
                  innerRadius={85}
                  fill={theme.colors.purpleSecondary}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomGraphicTooltip />} />
                <Legend
                  content={<CustomLegend payload={[]} />}
                  verticalAlign="middle"
                  align="right"
                  layout="vertical"
                  iconSize={12}
                />
              </PieChart>
            </ResponsiveContainer>

            <Flex
              vertical
              style={{
                padding: '13.5px 0px',
                width: '100%',
                marginTop: '32px',
              }}
            >
              <Typography.Text className="fs-12-bold text-gray-color">
                Cost of Purchased Energy (Arbitrage & Consumption)
                {':  $'}
                {formatValue(getCostValue())}
              </Typography.Text>
            </Flex>
          </>
        )}
      </Card>
      <Flex
        style={{
          padding: '22.5px 24px',
          border: `0.5px solid ${theme.colors.grayLightAccent}`,
          width: '100%',
          height: '83px',
        }}
      >
        <Typography.Text className="fs-12-regular text-gray-color">
          Revenue from Disharged Energy From Plant & Revenue from Arbitrage
          represent the Revenue from Total Discharged Energy
        </Typography.Text>
      </Flex>
    </Row>
  );
};
export default Earnings;

import { Col, Row } from 'antd';
import { motion } from 'framer-motion';

import CashFlow from './CashFlow';
import CashFlowTable from './CashFlowTable';
import Earnings from './Earnings';
import Ebitda from './Ebitda';
import IncomeBreakdown from './IncomeBreakdown';
import PlantBatteryBOS from './PlantBatteryBOS';
import ProfitAndLossTableCompact from './ProfilAndLossTableCompact/index';

const Finance = () => {
  const scrollAnimation = (direction: 'left' | 'right') => ({
    initial: { opacity: 0, x: direction === 'left' ? -100 : 100 },
    whileInView: { opacity: 1, x: 0 },
    transition: { type: 'spring', stiffness: 300, damping: 30 },
  });
  return (
    <Row gutter={24} justify="center" align="top">
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <IncomeBreakdown />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('right')}>
          <ProfitAndLossTableCompact />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <CashFlowTable />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('right')}>
          <PlantBatteryBOS />
        </motion.div>
      </Col>
      {/* <Col span={5}>
        <motion.div {...scrollAnimation('right')}>
          <UpfrontCost />
        </motion.div>
      </Col> */}
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <Ebitda />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('right')}>
          <CashFlow />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <Earnings />
        </motion.div>
      </Col>
    </Row>
  );
};
export default Finance;

import { TourStepProps } from 'antd';

export type StepReferences = {
  userProfile: HTMLElement;
  helpSection: HTMLElement;
  organizationSection: HTMLElement;
  plants: HTMLElement;
  createPlant: HTMLElement;
  viewDeactivatePlant: HTMLElement;
  scenarios: HTMLElement;
  createScenario: HTMLElement;
  viewDeactivateScenario: HTMLElement;
  storageSystems: HTMLElement;
  createStorageSystem: HTMLElement;
  viewDeactivateStorageSystem: HTMLElement;
  createSimulation: HTMLElement;
  simulationAssets: HTMLElement;
  assetSelectionEmptyFace: HTMLElement;
  assetSelectionFillFace: HTMLElement;
  reviewSimulationAssets: HTMLElement;
  runningSimulation: HTMLElement;
  completedSimulation: HTMLElement;
  exampleSimulation: HTMLElement;
};

export const getTourSteps = (
  stepReferences: StepReferences
): TourStepProps[] => [
  //0
  {
    title: 'User Profile',
    description:
      'In the User Profile section, you will find the Personal Settings area, where you can manage your personal preferences and change your password. Additionally, you can log out of the application from this section.',
    target: () => stepReferences.userProfile,
    placement: 'left',
    style: {
      right: '173px',
      top: '65px',
    },
  },
  //1
  {
    title: 'Help Section',
    description:
      "The Help section includes the Terms of Service, Privacy Policy, and Product Tour. Here, you can review the terms that govern the use of the application, understand how your data is handled, and revisit the product tour to familiarize yourself with the app's features.",
    target: () => stepReferences.helpSection,
    placement: 'left',
    style: {
      right: '255px',
      top: '65px',
    },
  },
  //2
  {
    title: 'Organization Section',
    description:
      'In the Organization section, you will find three key areas: User Management, Payments, and Settings. The User Management area allows you to view and invite users to the organization. The Payments section provides access to payment information, including saved cards and billing addresses. Lastly, the Settings section offers general information related to the organization.',
    target: () => stepReferences.organizationSection,
    placement: 'left',
    style: {
      right: '360px',
      top: '65px',
    },
  },
  //3
  {
    title: 'Plants',
    description:
      'RATIO SIM enables effective management of the core components of your energy system, known as "plants" (e.g., wind, pv, diesel generators). You can review the plant types available for your energy system directly from the table.',
    target: () => stepReferences.plants,
    placement: 'right',
    style: {
      left: '225px',
    },
  },
  //4
  {
    title: 'Create Plant',
    description:
      'You can seamlessly create plants, such as wind, pv, and diesel generators, directly from the platform using the "Create" button to meet your specific energy system requirements.',
    target: () => stepReferences.createPlant,
    placement: 'left',
  },
  //5
  {
    title: 'View, Duplicate or Deactivate Plant',
    description:
      'From the button located here, you can view, duplicate, or deactivate the plants you have created. The ability to deactivate a plant allows you to temporarily disable it without deleting it, giving you more control and flexibility in managing your plant operations.',
    target: () => stepReferences.viewDeactivatePlant,
    placement: 'left',
    style: {
      top: '420px',
      right: '185px',
    },
  },
  //6
  {
    title: 'Scenarios',
    description:
      '"Scenarios" allow you to simulate and analyze your energy systems under various conditions. In this section, you can create and manage scenarios to model system performance based on different assumptions and time periods. Scenarios contain financial parameters and market participation parameters that will be used in the simulation.',
    target: () => stepReferences.scenarios,
    placement: 'right',
    style: {
      left: '225px',
    },
  },
  //7
  {
    title: 'Create Scenario',
    description:
      'You can effortlessly create scenarios tailored to your energy system needs directly from the platform using the "Create" button. Define various conditions and assumptions to model and analyze your system’s performance effectively.',
    target: () => stepReferences.createScenario,
    placement: 'left',
  },
  //8
  {
    title: 'View, Duplicate or Deactivate Scenario',
    description:
      'From the button located here, you can view, duplicate, or deactivate the scenarios you have created. The ability to deactivate a scenario allows you to temporarily disable it without deleting it, giving you more control and flexibility in managing your scenario operations.',
    target: () => stepReferences.viewDeactivateScenario,
    placement: 'left',
    style: {
      top: '415px',
      right: '185px',
    },
  },
  //9
  {
    title: 'Storage Systems',
    description:
      'The "storage system" provides detailed information about battery components. You can register various storage systems and conduct analyses to assess their performance. Based on simulation results, you can achieve the optimal storage capacity.',
    target: () => stepReferences.storageSystems,
    placement: 'right',
    style: {
      left: '225px',
    },
  },
  //10
  {
    title: 'Create Storage System',
    description:
      'You can effortlessly set up and configure energy storage systems tailored to your needs directly from the platform using the "Create" button. Define different storage parameters and integration options to effectively model and analyze their impact on your energy system’s performance.',
    target: () => stepReferences.createStorageSystem,
    placement: 'left',
  },
  //11
  {
    title: 'View, Duplicate or Deactivate Storage System',
    description:
      'From the button located here, you can view, duplicate, or deactivate the storage systems you have created. The ability to deactivate a storage system allows you to temporarily disable it without deleting it, giving you more control and flexibility in managing your storage system operations.',
    target: () => stepReferences.viewDeactivateStorageSystem,
    placement: 'left',
    style: {
      top: '415px',
      right: '185px',
    },
  },
  //12
  {
    title: 'Create Simulation',
    description:
      "In this section, you can start a new simulation by selecting the required assets. We have created a sample simulation for you; to explore it, you can review the parameters we entered and selected by clicking the 'Next' button and see in detail how the simulation is structured.",
    target: () => stepReferences.createSimulation,
  },
  //13
  {
    title: 'Simulation Assets',
    description:
      'You can see the active assets found according to the intersection of the selected start date and year here.',
    target: () => stepReferences.simulationAssets,
  },
  //14
  {
    title: 'Asset Selection',
    description:
      'You can examine the found assets in detail through tables in this section. For a standalone storage simulation, selecting a plant is not required. However, if you have a hybrid system, you can add multiple plants (e.g., solar power plant and wind power plant). Please note that you must select one scenario and one storage system to proceed.',
    target: () => stepReferences.assetSelectionEmptyFace,
    placement: 'top',
  },
  //15
  {
    title: 'Asset Selection',
    description:
      'For this simulation, as shown in the table, we will proceed by selecting one asset of each type.',
    target: () => stepReferences.assetSelectionFillFace,
    placement: 'top',
  },
  //16
  {
    title: 'Review Simulation Assets',
    description:
      'Here, you can view and review the assets you have selected for creating the simulation. You are now ready to proceed with generating the simulation using the chosen assets.',
    target: () => stepReferences.reviewSimulationAssets,
    placement: 'bottom',
  },
  //17
  {
    title: 'Running Simulation',
    description:
      'In this section, you can see that the simulation is running. When the simulation is complete, the results will be transferred to the "Completed Simulations" table in the lower section.',
    target: () => stepReferences.runningSimulation,
    prevButtonProps: {
      style: {
        display: 'none',
      },
    },
  },
  //18
  {
    title: 'Completed Simulation',
    description:
      'In the "Completed Simulations" table, you can examine the simulations you have previously run in detail.',
    target: () => stepReferences.completedSimulation,
  },
  //19
  {
    title: 'Example Simulation',
    description:
      'You can click this button to view example simulation results.',
    target: () => stepReferences.exampleSimulation,
    placement: 'left',
  },
];

import { useEffect, useMemo, useState } from 'react';

import { Flex, FormInstance, notification, Typography } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';

import { useAssets } from '@/hooks/useAssets';
import useAuth from '@/hooks/useAuth';
import useNewSimulationPlantTableColumns from '@/hooks/useNewSimulationPlantsTableColumns';
import useNewSimulationScenarioTableColumns from '@/hooks/useNewSimulationScenarioTableColumns';
import useNewSimulationStorageSystemTableColumns from '@/hooks/useNewSimulationStorageSystemTableColumns';
import { useProductTourSelectAsset } from '@/hooks/useProductTourSelectAsset';
import PlantDrawer from '@/pages/Plants/Drawer/PlantGenericDrawer';
import ScenarioDrawer from '@/pages/Scenarios/Drawer/ScenarioGenericDrawer';
import StorageDrawer from '@/pages/StorageSystems/Drawer/StorageGenericDrawer';
import useProductTourStore from '@/store/useProductTourStore';
import { useSelectedAssetsStore } from '@/store/useSelectedAssetsStore';
import { ExtendedItem } from '@/types/collapse';
import { DrawerType } from '@/types/global';
import { Plant } from '@/types/plant';
import { Scenario } from '@/types/scenario';
import { StorageSystem } from '@/types/storageSystem';

import CustomCollapse from '../RCollapseSim';
import Table from '../RTable';

type Props = {
  setIsCollapseKeysValid: (check: boolean) => void;
  form: FormInstance;
  current?: number;
};

type Asset = {
  id: string;
  name: string;
};

type Assets = {
  plants: Asset[];
  scenario: Asset;
  storageSystem: Asset;
};

const PlantsStorageScenarioCollapse: React.FC<Props> = ({
  setIsCollapseKeysValid,
  form,
  current,
}: Props) => {
  const { selectedAssets, setSelectedAssets } = useSelectedAssetsStore();
  const [drawerConfig, setDrawerConfig] = useState<{
    type?: string;
    id?: string;
  }>({});
  const simulationStartDate = form.getFieldValue('simulationStartDate');
  const years = form.getFieldValue('years');
  const {
    assets = { plants: [], scenarios: [], storageSystems: [] },
    pagination,
    handlePaginationChange,
    plantsData = { count: 0 },
    scenarioData = { count: 0 },
    storageSystemData = { count: 0 },
  } = useAssets(simulationStartDate, years);

  const { isTourActive } = useProductTourStore();
  const { activeAssetSelectionRef } = useProductTourSelectAsset(current, form);

  const { user } = useAuth();
  const assetLimit = user?.organization.subscription.plan.simAssetLimit;

  const [drawerPlant, setDrawerPlant] = useState<DrawerType<Plant>>({
    type: null,
    data: null,
  });
  const [drawerScenario, setDrawerScenario] = useState<DrawerType<Scenario>>({
    type: null,
    data: null,
  });
  const [drawerStorage, setDrawerStorage] = useState<DrawerType<StorageSystem>>(
    {
      type: null,
      data: null,
    }
  );

  useEffect(() => {
    if (drawerConfig.type === 'Plant') {
      setDrawerPlant({
        type: 'view',
        data: { id: String(drawerConfig.id) },
      });
    }
    if (drawerConfig.type === 'Scenario') {
      setDrawerScenario({
        type: 'view',
        data: { id: String(drawerConfig.id) },
      });
    }
    if (drawerConfig.type === 'Storage') {
      setDrawerStorage({
        type: 'view',
        data: { id: String(drawerConfig.id) },
      });
    }
  }, [drawerConfig]);

  //TODO
  const prepareSimulationItem = async (id: string, type: string) => {
    setDrawerConfig({ id, type });
  };

  useEffect(() => {
    const isAnySelectionMade =
      // selectedAssets.plants.length > 0 &&
      selectedAssets.scenario.id !== '' &&
      selectedAssets.storageSystem.id !== '';
    setIsCollapseKeysValid(isAnySelectionMade);
  }, [
    selectedAssets.plants,
    selectedAssets.scenario,
    selectedAssets.storageSystem,
    setIsCollapseKeysValid,
  ]);

  const plantsTableColumns = useNewSimulationPlantTableColumns({
    prepareSimulationItem,
  });

  const scenarioTableColumns = useNewSimulationScenarioTableColumns({
    prepareSimulationItem,
  });

  const storageSystemTableColumns = useNewSimulationStorageSystemTableColumns({
    prepareSimulationItem,
  });

  const plantsRowSelection = useMemo<TableRowSelection<Plant>>(
    () => ({
      type: 'checkbox',
      hideSelectAll: true,
      selectedRowKeys: selectedAssets.plants.map((asset) => +asset.id),
      columnTitle: '',
      onChange: (_, selectedRows) => {
        if (selectedRows.length <= 5) {
          setSelectedAssets({
            ...selectedAssets,
            plants: selectedRows.map((row) => ({
              id: row.id.toString(),
              name: row.name,
            })),
          } as Assets);
        } else {
          notification.error({
            message: 'Maximum 5 plants can be selected',
          });
        }
      },
    }),
    [selectedAssets, setSelectedAssets]
  );

  const scenarioRowSelection = useMemo<TableRowSelection<Scenario>>(
    () => ({
      type: 'radio',
      columnTitle: '',
      selectedRowKeys: [selectedAssets.scenario.id],
      onChange: (_, selectedRows) => {
        if (selectedRows[0]) {
          setSelectedAssets({
            ...selectedAssets,
            scenario: {
              id: selectedRows[0].id,
              name: selectedRows[0].name,
            },
          });
        }
      },
    }),
    [selectedAssets, setSelectedAssets]
  );

  const storageSystemRowSelection = useMemo<TableRowSelection<StorageSystem>>(
    () => ({
      type: 'radio',
      columnTitle: '',
      selectedRowKeys: [selectedAssets.storageSystem.id],
      onChange: (_, selectedRows) => {
        if (selectedRows[0]) {
          setSelectedAssets({
            ...selectedAssets,
            storageSystem: {
              id: selectedRows[0].id,
              name: selectedRows[0].name,
            },
          });
        }
      },
    }),
    [selectedAssets, setSelectedAssets]
  );

  const items: ExtendedItem[] = useMemo(
    () => [
      {
        key: '1',
        header: (
          <Flex vertical>
            <Typography.Text className="text-black-color">
              Plants
            </Typography.Text>
            <Typography.Text className="fs-12-regular text-gray-color">
              {plantsData?.count} plants found
            </Typography.Text>
          </Flex>
        ),
        children: (
          <Table
            rowKey="id"
            columns={plantsTableColumns}
            rowSelection={plantsRowSelection}
            dataSource={assets.plants}
            bordered
            size="small"
            pagination={{
              total: plantsData?.count,
              pageSize: pagination.plants.pageSize,
              current: pagination.plants.current,
              showSizeChanger: true,
              pageSizeOptions: ['7', '10', '20', '50', '100'],
              hideOnSinglePage: false,
              onChange: (page, pageSize) =>
                handlePaginationChange('plants', page, pageSize),
              onShowSizeChange: (current, size) =>
                handlePaginationChange('plants', current, size),
            }}
            onRow={(_record, index) => {
              return {
                ref: index === 0 ? activeAssetSelectionRef : undefined,
                onClick: () => {
                  // Optional: handle row click
                },
              };
            }}
          />
        ),
        successMessage: `${selectedAssets.plants.length} plants selected`,
        errorMessage: 'Unselected',
        condition: selectedAssets.plants.length !== 0,
        closableWhenUnselected: true,
      },
      {
        key: '2',
        header: (
          <Flex vertical>
            <Typography.Text className="text-black-color">
              Scenario
            </Typography.Text>
            <Typography.Text className="fs-12-regular text-gray-color">
              {scenarioData?.count} scenarios found
            </Typography.Text>
          </Flex>
        ),
        children: (
          <Table
            rowKey="id"
            columns={scenarioTableColumns}
            rowSelection={scenarioRowSelection}
            dataSource={assets.scenarios}
            bordered
            size="small"
            pagination={{
              total: scenarioData?.count,
              pageSize: pagination.scenario.pageSize,
              current: pagination.scenario.current,
              showSizeChanger: true,
              pageSizeOptions: ['7', '10', '20', '50', '100'],
              hideOnSinglePage: false,
              onChange: (page, pageSize) =>
                handlePaginationChange('scenario', page, pageSize),
              onShowSizeChange: (current, size) =>
                handlePaginationChange('scenario', current, size),
            }}
          />
        ),
        successMessage: `1 scenario selected`,
        errorMessage: 'Unselected',
        condition: selectedAssets.scenario.id !== '',
        closableWhenUnselected: false,
      },
      {
        key: '3',
        header: (
          <Flex vertical>
            <Typography.Text className="text-black-color">
              Storage System
            </Typography.Text>
            <Typography.Text className="fs-12-regular text-gray-color">
              {storageSystemData?.count} storage systems found
            </Typography.Text>
          </Flex>
        ),
        children: (
          <Table
            rowKey="id"
            columns={storageSystemTableColumns}
            rowSelection={storageSystemRowSelection}
            dataSource={assets.storageSystems}
            bordered
            size="small"
            pagination={{
              total: storageSystemData?.count,
              pageSize: pagination.storage.pageSize,
              current: pagination.storage.current,
              showSizeChanger: true,
              pageSizeOptions: ['7', '10', '20', '50', '100'],
              hideOnSinglePage: false,
              onChange: (page, pageSize) =>
                handlePaginationChange('storage', page, pageSize),
              onShowSizeChange: (current, size) =>
                handlePaginationChange('storage', current, size),
            }}
          />
        ),
        successMessage: `1 storage system selected`,
        errorMessage: 'Unselected',
        condition: selectedAssets.storageSystem.id !== '',
        closableWhenUnselected: true,
      },
    ],

    [
      assets,
      pagination,
      handlePaginationChange,
      plantsData?.count,
      scenarioData?.count,
      storageSystemData?.count,
      plantsTableColumns,
      scenarioTableColumns,
      storageSystemTableColumns,
      plantsRowSelection,
      scenarioRowSelection,
      storageSystemRowSelection,
      selectedAssets,
      activeAssetSelectionRef,
    ]
  );

  return (
    <>
      <CustomCollapse
        dataSource={items}
        activeKey={isTourActive ? ['1'] : undefined}
      ></CustomCollapse>
      {assetLimit && plantsData && (
        <PlantDrawer
          drawerOptions={drawerPlant}
          setDrawerOptions={setDrawerPlant}
        />
      )}
      {assetLimit && scenarioData && (
        <ScenarioDrawer
          drawerOptions={drawerScenario}
          setDrawerOptions={setDrawerScenario}
        />
      )}
      {assetLimit && storageSystemData && (
        <StorageDrawer
          drawerOptions={drawerStorage}
          setDrawerOptions={setDrawerStorage}
        />
      )}
    </>
  );
};

export default PlantsStorageScenarioCollapse;

import { useEffect, useMemo, useRef, useState } from 'react';

import { Col, Row, Space, Spin, Typography } from 'antd';
import Lottie from 'react-lottie-player';
import useSWR from 'swr';

import IconDown from '@/assets/icons/icon-down.svg?react';
import loadingLottie from '@/assets/lotties/ratio-loading-lottie.json';
import { AnimatedText } from '@/components/RTextAnimation';
import { Plan } from '@/types/plan';

import ComparisonTable from './ComparisonTable';
import PlansCards from './PlansCards';
import StyledPlans from './styles';

const Plans = () => {
  const [yearly, setYearly] = useState<boolean>(true);

  const [showComparison, setShowComparison] = useState<boolean>(false);
  const { data, isLoading, error } = useSWR('/payment/plans');
  const comparisonRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const overlayTextArray = useMemo(() => {
    return [
      'Configuring organization settings.',
      'Building perfect experience for your needs...',
      'The final touches are being made.',
    ];
  }, []);
  const [overlayText, setOverlayText] = useState<string>(overlayTextArray[0]);

  useEffect(() => {
    if (showComparison) {
      comparisonRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [showComparison]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        if (currentIndex < overlayTextArray.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setOverlayText(overlayTextArray[currentIndex + 1]);
        } else {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, overlayTextArray, loading]);

  useEffect(() => {
    if (data) {
      data.forEach((item: Plan) => {
        if (item.name === 'enterprise') {
          item.feature = [
            `${item.durationAmount} ${item.durationUnit}`,
            `${item.dataRetentionAmount} ${item.dataRetentionUnit}s data retention`,
            `Unlimited simultaneous simulations`,
            `Unlimited total user`,
            `Unlimited simulation assets`,
            `${item.other.support}`,
          ];
          item.amount = 'Contact';
        } else if (item.name === 'demo') {
          item.amount = 'Free';
          item.feature = [
            `${item.durationAmount} ${item.durationUnit}`,
            `${item.dataRetentionAmount} ${item.dataRetentionUnit}s data retention`,
            `${item.simultaneousSimLimit} simultaneous simulations`,
            `${item.userLimit} total user`,
            `${item.simAssetLimit} simulation assets`,
            `${item.other.support}`,
          ];
        } else {
          item.feature = [
            `${item.durationAmount} ${item.durationUnit}`,
            `${item.dataRetentionAmount} ${item.dataRetentionUnit}s data retention`,
            `${item.simultaneousSimLimit} simultaneous simulations`,
            `${item.userLimit} total user`,
            `${item.simAssetLimit} simulation assets`,
            `${item.other.support}`,
          ];
        }
      });
    }
  }, [data]);

  if (loading) {
    return (
      <Row align="top" justify="center" className="loading-wrapper w-100">
        <Col>
          <Lottie
            style={{ height: '120px' }}
            animationData={loadingLottie}
            loop={true}
            play
          />
          <Row>
            <AnimatedText className="animated-text" text={overlayText} />
          </Row>
        </Col>
      </Row>
    );
  }

  if (isLoading) {
    return (
      <StyledPlans
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
          width: '100%',
        }}
      >
        <Spin />
      </StyledPlans>
    );
  }
  if (error) {
    return (
      <Row justify="center" className="mb-64">
        <Typography.Text type="danger">Failed to load data</Typography.Text>
      </Row>
    );
  }

  const monthlyPlan = data?.filter(
    (item: { durationUnit: string }) => item.durationUnit === 'month'
  );

  const yearlyPlan = data?.filter(
    (item: { durationUnit: string }) => item.durationUnit === 'year'
  );

  const selectedPlan = yearly ? yearlyPlan : monthlyPlan;

  const plansWithFeatures: Plan[] = selectedPlan || [];

  const percentage = (
    100 -
    (yearlyPlan[0].amount / (12 * monthlyPlan[0].amount)) * 100
  ).toFixed(0);

  const toggleComparison = () => {
    setShowComparison((prev) => !prev);
  };

  return (
    <StyledPlans ref={scrollContainerRef}>
      <Col span={24}>
        <PlansCards
          monthlyPlan={monthlyPlan}
          yearlyPlan={yearlyPlan}
          yearly={yearly}
          plansWithFeatures={plansWithFeatures}
          handleSwitch={() => setYearly(!yearly)}
          percentage={percentage}
          data={data}
          setLoading={setLoading}
        />
      </Col>
      <Col span={24}>
        <Space className="compare-title" onClick={() => toggleComparison()}>
          <Typography.Title className="fs-20-bold text-dark-color" level={3}>
            Compare Plans
          </Typography.Title>
          <IconDown
            style={{ transform: showComparison ? 'rotate(180deg)' : undefined }}
          />
        </Space>
      </Col>
      <div ref={comparisonRef} />
      {showComparison && (
        <Col span={24}>
          <ComparisonTable
            data={data}
            yearly={yearly}
            monthlyPlan={monthlyPlan}
            yearlyPlan={yearlyPlan}
            handleSwitch={() => setYearly(!yearly)}
            percentage={percentage}
          />
        </Col>
      )}
    </StyledPlans>
  );
};

export default Plans;

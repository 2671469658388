import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import useSWR from 'swr';

import { Plant, PlantsResponse } from '@/types/plant';
import { Scenario, ScenariosResponse } from '@/types/scenario';
import { StorageSystem, StorageSystemsResponse } from '@/types/storageSystem';

export const useAssets = (simulationStartDate: string, years: number) => {
  const [assets, setAssets] = useState({
    plants: [] as Plant[],
    scenarios: [] as Scenario[],
    storageSystems: [] as StorageSystem[],
  });

  const defaultPageSize = 7;

  const [pagination, setPagination] = useState({
    plants: { current: 1, pageSize: defaultPageSize },
    scenario: { current: 1, pageSize: defaultPageSize },
    storage: { current: 1, pageSize: defaultPageSize },
  });

  const buildQuery = (type: string, current: number, pageSize: number) => {
    const formattedStartDate = dayjs(simulationStartDate).format('YYYY-MM-DD');
    return `/${type}/?limit=${pageSize}&offset=${
      (current - 1) * pageSize
    }&numYears=${years}&startDate=${formattedStartDate}&isActive=true`;
  };

  const { data: plantsData, mutate: plantsDataMutate } = useSWR<PlantsResponse>(
    simulationStartDate
      ? buildQuery(
          'plant',
          pagination.plants.current,
          pagination.plants.pageSize
        )
      : null
  );

  const { data: scenarioData, mutate: scenarioDataMutate } =
    useSWR<ScenariosResponse>(
      simulationStartDate
        ? buildQuery(
            'scenario',
            pagination.scenario.current,
            pagination.scenario.pageSize
          )
        : null
    );

  const { data: storageSystemData, mutate: storageSystemMutate } =
    useSWR<StorageSystemsResponse>(
      simulationStartDate
        ? buildQuery(
            'storage',
            pagination.storage.current,
            pagination.storage.pageSize
          )
        : null
    );

  useEffect(() => {
    if (plantsData)
      setAssets((prev) => ({ ...prev, plants: plantsData.results }));
    if (scenarioData)
      setAssets((prev) => ({ ...prev, scenarios: scenarioData.results }));
    if (storageSystemData)
      setAssets((prev) => ({
        ...prev,
        storageSystems: storageSystemData.results,
      }));
  }, [plantsData, scenarioData, storageSystemData]);

  const handlePaginationChange = (
    type: 'plants' | 'scenario' | 'storage',
    current: number,
    pageSize: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      [type]: { current, pageSize },
    }));
  };

  return {
    assets,
    pagination,
    setAssets,
    handlePaginationChange,
    plantsData,
    scenarioData,
    storageSystemData,
    plantsDataMutate,
    scenarioDataMutate,
    storageSystemMutate,
  };
};

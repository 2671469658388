import { useState } from 'react';

import { TableColumnsType, Typography } from 'antd';
import { useParams } from 'react-router';
import useSWR from 'swr';

import IconDown from '@/assets/icons/icon-down.svg?react';
import IconRight from '@/assets/icons/icon-right.svg?react';
import Table from '@/components/RTable';
import useCalculatedNPVandPayback from '@/hooks/useCalculatedNPVandPayback';
import {
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
  SimulationResultYearlyFinancialAnalysisData,
} from '@/types/simulations';
import { converToMillion } from '@/utils';

import StyledProfit from './styles';

interface DataType {
  key: React.Key;
  name: React.ReactNode;
  total?: string;
  children?: DataType[];
}

const CashFlowWithStorageTable = ({
  hurdleRate,
  defaultHurdleRate,
}: {
  hurdleRate: number;
  defaultHurdleRate: number;
}) => {
  const resultId = useParams<Record<string, string>>();
  const { data: financialData } =
    useSWR<SimulationResultYearlyFinancialAnalysis>(
      `/simulation/${resultId.resultId}/yearly_financial_analysis/`,
      {
        revalidateOnFocus: false,
      }
    );
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const cashFlows = financialData?.graphData.map((item) => item.cashFlow);

  const { calculatedNPV, calculatedPaybackPeriod, yearlyNPVs } =
    useCalculatedNPVandPayback(
      financialData?.graphData,
      cashFlows,
      hurdleRate,
      defaultHurdleRate,
      simulationData?.result.netPresentValue,
      simulationData?.result.discountedPaybackPeriod
    );

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const onExpand = (expanded: boolean, record: DataType) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customExpandIcon = (props: any) => {
    if (!props.record.children) {
      return <span style={{ marginRight: '22px' }} />;
    }
    if (props.expanded) {
      return (
        <IconDown
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    } else {
      return (
        <IconRight
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    }
  };

  if (!financialData || !simulationData) {
    return null;
  }

  const years = [
    ...new Set(financialData.graphData.map((item) => item.year)),
  ].sort((a, b) => a - b);

  const scrollX = 600 + years.length * 100;

  const getValuesForYears = (
    key: keyof SimulationResultYearlyFinancialAnalysisData
  ) => {
    const values: Record<string, string> = {};
    years.forEach((year) => {
      const data = financialData.graphData.find((item) => item.year === year);
      if (key === 'netPresentValue' && hurdleRate !== defaultHurdleRate) {
        values[year.toString()] = yearlyNPVs[year.toString()]
          ? `${converToMillion(Number(yearlyNPVs[year.toString()]))} $`
          : '0 $';
      } else {
        values[year.toString()] = data
          ? `${converToMillion(data[key])} $`
          : '0 $';
      }
    });
    return values;
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 350,
      fixed: 'left',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
    ...years.map((year) => ({
      title: year.toString(),
      dataIndex: year.toString(),
      key: year.toString(),
      width: 100,
    })),
  ];

  const dataSource = [
    {
      key: '1',
      name: 'EBITDA',
      total: `${converToMillion(simulationData?.result.ebitda)} $`,
      ...getValuesForYears('ebitda'),
    },
    {
      key: '2',
      name: 'Tax',
      total: `${converToMillion(simulationData?.result.tax)} $`,
      ...getValuesForYears('tax'),
    },
    {
      key: '3',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Net Profit
        </Typography.Text>
      ),
      total: `${converToMillion(simulationData?.result.netProfit)} $`,
      ...getValuesForYears('netProfit'),
    },
    {
      key: '7',
      name: 'CAPEX',
    },
    {
      key: '8',
      name: 'Capex',
      total: `${converToMillion(simulationData?.result.capexWithStorage)} $`,
      ...getValuesForYears('capexWithStorage'),
      children: [
        {
          key: '8-1',
          name: 'Initial Capex',
          total: `${converToMillion(simulationData?.result.initialCapex)} $`,
          ...getValuesForYears('initialCapex'),
        },
        {
          key: '8-2',
          name: 'Total Loan Payment',
          total: `${converToMillion(simulationData?.result.totalLoanPayment)} $`,
          ...getValuesForYears('totalLoanPayment'),
          children: [
            {
              key: '8-2-1',
              name: 'Principal Payment',
              total: `${converToMillion(simulationData?.result.totalPrincipalPayment)} $`,
              ...getValuesForYears('totalPrincipalPayment'),
            },
            {
              key: '8-2-2',
              name: 'Interest Expense',
              total: `${converToMillion(simulationData?.result.totalInterestExpense)} $`,
              ...getValuesForYears('totalInterestExpense'),
            },
          ],
        },
      ],
    },
    {
      key: '9',
      name: 'Residual Values',
      total: `${converToMillion(simulationData?.result.totalResidual)} $`,
      ...getValuesForYears('totalResidual'),

      children: [
        {
          key: '9-1',
          name: 'Plant Residual Value',
          total: `${converToMillion(simulationData?.result.residualPlant)} $`,
          ...getValuesForYears('residualPlant'),
        },
        {
          key: '9-2',
          name: 'Battery Residual Value',
          total: `${converToMillion(simulationData?.result.residualBattery)} $`,
          ...getValuesForYears('residualBattery'),
        },
        {
          key: '9-3',
          name: 'BOS Residual Value',
          total: `${converToMillion(simulationData?.result.residualBos)} $`,
          ...getValuesForYears('residualBos'),
        },
      ],
    },
    {
      key: '10',
      name: <span></span>,
    },
    {
      key: '11',
      name: 'Cash Flow From Financing Activities',
      total: `${converToMillion(simulationData?.result.cashFlow)} $`,
      ...getValuesForYears('cashFlow'),
    },
    {
      key: '12',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Net Cash Flow
        </Typography.Text>
      ),
      total: `${converToMillion(calculatedNPV)} $`,
      ...getValuesForYears('netPresentValue'),
    },
    {
      key: '13',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Discounted Payback Period : {calculatedPaybackPeriod?.toFixed(1)}{' '}
          years
        </Typography.Text>
      ),
    },
  ];

  return (
    <StyledProfit>
      <Table
        dataSource={dataSource}
        columns={columns}
        expandable={{
          expandedRowKeys,
          onExpand,
          expandIcon: customExpandIcon,
          rowExpandable: (record) => !!record.children,
        }}
        pagination={false}
        scroll={{ x: scrollX }}
      />
    </StyledProfit>
  );
};

export default CashFlowWithStorageTable;

import { useEffect } from 'react';

import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  InputNumber,
  DatePickerProps,
  FormInstance,
} from 'antd';
import dayjs from 'dayjs';

import { simulationApi } from '@/api';
import IconCalendar from '@/assets/icons/icon-calender.svg?react';

enum SIMULATION_TYPES {
  SIMULATION_NAME = 'simulationName',
  DESCRIPTION = 'simulationDescription',
  START_DATE = 'simulationStartDate',
  YEAR = 'years',
}

type Props = {
  form: FormInstance;
  setIsFormValid: (check: boolean) => void;
};

const SimulationForm: React.FC<Props> = ({ setIsFormValid, form }) => {
  const DEFAULT_YEAR = 1;

  const validateForm = () => {
    const values = form.getFieldsValue([
      SIMULATION_TYPES.SIMULATION_NAME,
      SIMULATION_TYPES.START_DATE,
      SIMULATION_TYPES.YEAR,
    ]);
    const errorsExist = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    const fieldsEmpty = [
      SIMULATION_TYPES.SIMULATION_NAME,
      SIMULATION_TYPES.START_DATE,
    ].some((field) => !values[field]);

    const invalidYear = values[SIMULATION_TYPES.YEAR] <= 0;

    setIsFormValid(!errorsExist && !fieldsEmpty && !invalidYear);
  };

  useEffect(() => {
    form.setFieldsValue({
      [SIMULATION_TYPES.SIMULATION_NAME]: form.getFieldValue('simulationName'),
      [SIMULATION_TYPES.DESCRIPTION]: form.getFieldValue(
        'simulationDescription'
      ),
      [SIMULATION_TYPES.START_DATE]: form.getFieldValue('simulationStartDate')
        ? dayjs(form.getFieldValue('simulationStartDate'))
        : null,
      [SIMULATION_TYPES.YEAR]: form.getFieldValue('years'),
    });

    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleFormChange = () => {
    validateForm();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFieldChange = (field: SIMULATION_TYPES, value: any) => {
    if (
      field === SIMULATION_TYPES.YEAR &&
      (value === null || value === undefined)
    ) {
      value = DEFAULT_YEAR;
    }

    if (field === SIMULATION_TYPES.START_DATE && value) {
      value = dayjs(value);
    }

    form.setFieldsValue({ [field]: value });
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFieldChange(SIMULATION_TYPES.SIMULATION_NAME, event.target.value);
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFieldChange(SIMULATION_TYPES.DESCRIPTION, event.target.value);
  };

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    handleFieldChange(SIMULATION_TYPES.START_DATE, date ? dayjs(date) : null);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="newSimulationForm"
      initialValues={{
        [SIMULATION_TYPES.SIMULATION_NAME]:
          form.getFieldValue('simulationName'),
        [SIMULATION_TYPES.DESCRIPTION]: form.getFieldValue(
          'simulationDescription'
        ),
        [SIMULATION_TYPES.START_DATE]: form.getFieldValue('simulationStartDate')
          ? dayjs(form.getFieldValue('simulationStartDate'))
          : null,
        [SIMULATION_TYPES.YEAR]: DEFAULT_YEAR,
      }}
      onFieldsChange={handleFormChange}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Simulation Name"
            name={SIMULATION_TYPES.SIMULATION_NAME}
            hasFeedback
            rules={[
              { required: true, message: 'Simulation name cannot be empty.' },
              () => ({
                async validator(_rule, value) {
                  if (
                    value &&
                    typeof value === 'string' &&
                    value.trim().length > 0
                  ) {
                    return new Promise<void>((resolve, reject) => {
                      simulationApi
                        .searchSimulationByExactName(encodeURIComponent(value))
                        .then((response) => {
                          if (response.results?.length > 0) {
                            reject('Simulation name already exists.');
                          } else {
                            resolve();
                          }
                        })
                        .catch(() => resolve());
                    });
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Simulation Name"
              onChange={onNameChange}
              value={form.getFieldValue('simulationName')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Description"
            name={SIMULATION_TYPES.DESCRIPTION}
            rules={[
              {
                max: 140,
                message: 'Description must not exceed 140 characters!',
              },
            ]}
          >
            <Input
              placeholder="Description"
              onChange={onDescriptionChange}
              value={form.getFieldValue('simulationDescription')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label="Select Start Date"
            name={SIMULATION_TYPES.START_DATE}
            rules={[{ required: true, message: 'Please select a start date!' }]}
          >
            <DatePicker
              onChange={onChangeDate}
              suffixIcon={<IconCalendar fill="#888" />}
              style={{ width: '100%' }}
              value={
                form.getFieldValue('simulationStartDate')
                  ? dayjs(form.getFieldValue('simulationStartDate'))
                  : null
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Year"
            name={SIMULATION_TYPES.YEAR}
            rules={[{ required: true, message: 'Please input a year!' }]}
          >
            <InputNumber
              value={form.getFieldValue('years')}
              onChange={(year) =>
                handleFieldChange(SIMULATION_TYPES.YEAR, year as number)
              }
              placeholder="Input Year"
              style={{ width: '100%' }}
              precision={0}
              min={1}
              step={1}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SimulationForm;

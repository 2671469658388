import styled from 'styled-components';

import Card from '@/components/RCard';

export default styled(Card)`
  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.colors.primary};
    height: 32px;
  }
  .ant-input.ant-input-outlined {
    height: 32px;
  }
`;

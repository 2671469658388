import { useState, useEffect } from 'react';

import { Flex, Select, Spin, Typography, Button, Input } from 'antd';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Tooltip from '@/components/RTooltip';
import { Scenario } from '@/types/scenario';
import { SimulationResult } from '@/types/simulations';

import CashFlowStorageTable from './CashFlowStorage';
import CashFlowWithoutStorageTable from './CashFlowWithoutStorage';
import CashFlowWithStorageTable from './CashFlowWithStorage';
import StyledCard from './styles';

interface HurdleRateState {
  temp: string;
  custom: number;
  default: number;
}

const CashFlowTable = () => {
  const theme = useTheme();
  const [storageSelection, setStorageSelection] = useState('withStorage');
  const [hurdleRate, setHurdleRate] = useState<HurdleRateState>({
    temp: '',
    custom: 0,
    default: 0,
  });

  const resultId = useParams<Record<string, string>>();
  const {
    data: simulationData,
    isLoading,
    error,
  } = useSWR<SimulationResult>(`/simulation/${resultId.resultId}/`, {
    revalidateOnFocus: false,
  });
  const scenarioId = simulationData?.scenario?.id;
  const { data: scenarioData } = useSWR<Scenario>(`/scenario/${scenarioId}/`, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (scenarioData?.hurdleRate !== undefined) {
      setHurdleRate({
        temp: scenarioData.hurdleRate.toString(),
        custom: scenarioData.hurdleRate,
        default: scenarioData.hurdleRate,
      });
    }
  }, [scenarioData]);

  const handleHurdleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setHurdleRate((prev) => ({ ...prev, temp: '' }));
      return;
    }
    const numValue = Number(value);
    if (!isNaN(numValue) && numValue >= 1 && numValue <= 100) {
      setHurdleRate((prev) => ({ ...prev, temp: value }));
    }
  };

  const applyHurdleRate = () => {
    const numValue = Number(hurdleRate.temp);
    if (!isNaN(numValue) && numValue >= 1 && numValue <= 100) {
      setHurdleRate((prev) => ({ ...prev, custom: numValue }));
    }
  };

  const handleStorageSelection = (value: string) => {
    setStorageSelection(value);
    setHurdleRate((prev) => ({
      ...prev,
      temp: prev.default.toString(),
      custom: prev.default,
    }));
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Flex justify="center" align="center" style={{ height: 361 }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      );
    }

    if (error) {
      return (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 361 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          <Typography.Text className="fs-14-regular text-gray-color">
            An error occured while fetching data. Please check your inputs or
            contact support if the issue persists.
          </Typography.Text>
        </Flex>
      );
    }

    switch (storageSelection) {
      case 'withStorage':
        return (
          <CashFlowWithStorageTable
            hurdleRate={hurdleRate.custom}
            defaultHurdleRate={hurdleRate.default}
          />
        );
      case 'withoutStorage':
        return (
          <CashFlowWithoutStorageTable
            hurdleRate={hurdleRate.custom}
            defaultHurdleRate={hurdleRate.default}
          />
        );
      case 'storage':
        return (
          <CashFlowStorageTable
            hurdleRate={hurdleRate.custom}
            defaultHurdleRate={hurdleRate.default}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledCard
      title={
        <Flex gap={8} align="center">
          <Typography.Title level={4} className="fs-17-bold">
            Cash Flow
          </Typography.Title>
          <Tooltip
            title="Cash Flow"
            description="Cashflow starting from EBITDA, adjusted for taxes, Capex, and residuals."
          >
            <IconInfo />
          </Tooltip>
        </Flex>
      }
      styles={{ header: { padding: '17.25px 24px' } }}
      $paddingBody="12px 0"
      style={{ marginTop: '24px' }}
      extra={
        <Flex gap={16} align="center">
          <Flex gap={8} align="center">
            <Typography.Text>Hurdle Rate (%) :</Typography.Text>
            <motion.div
              whileTap={{ scale: 1.02 }}
              transition={{ type: 'spring', stiffness: 300, damping: 24 }}
            >
              <Input
                type="number"
                onChange={handleHurdleRateChange}
                value={hurdleRate.temp}
                style={{ width: '120px' }}
                min={1}
                max={100}
                addonAfter={
                  <Button
                    type="text"
                    size="small"
                    onClick={applyHurdleRate}
                    className="hurdle-rate-button"
                    icon={'↵'}
                  />
                }
                onPressEnter={applyHurdleRate}
              />
            </motion.div>
          </Flex>
          <motion.div
            whileTap={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 300, damping: 24 }}
          >
            <Select
              placeholder="Storage Selection"
              style={{ minWidth: '150px', width: '150px' }}
              value={storageSelection}
              onChange={handleStorageSelection}
            >
              <Select.Option value="withStorage">With Storage</Select.Option>
              <Select.Option value="withoutStorage">
                Without Storage
              </Select.Option>
              <Select.Option value="storage">Storage</Select.Option>
            </Select>
          </motion.div>
        </Flex>
      }
    >
      {renderContent()}
    </StyledCard>
  );
};

export default CashFlowTable;

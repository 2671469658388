import { useState } from 'react';

import { Typography } from 'antd';
import { TableColumnsType } from 'antd/lib';
import { useParams } from 'react-router';
import useSWR from 'swr';

import IconDown from '@/assets/icons/icon-down.svg?react';
import IconRight from '@/assets/icons/icon-right.svg?react';
import Table from '@/components/RTable';
import {
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
  SimulationResultYearlyFinancialAnalysisData,
} from '@/types/simulations';
import { converToMillion } from '@/utils';

import StyledProfit from './styles';

interface DataType {
  key: string;
  name: string | JSX.Element;
  total: string;
  [key: string]: string | number | JSX.Element | undefined;
}

const ProfitAndLossTable = () => {
  const resultId = useParams<Record<string, string>>();
  const { data: financialData } =
    useSWR<SimulationResultYearlyFinancialAnalysis>(
      `/simulation/${resultId.resultId}/yearly_financial_analysis/`
    );

  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onExpand = (expanded: boolean, record: any) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customExpandIcon = (props: any) => {
    if (!props.record.children) {
      return <span style={{ marginRight: '22px' }} />;
    }
    if (props.expanded) {
      return (
        <IconDown
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    } else {
      return (
        <IconRight
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    }
  };

  let years: number[] = [];

  financialData?.graphData.forEach((item) => {
    years.push(item.year);
  });

  const scrollX = 775 + years.length * 100;

  years = [...new Set(years)].sort((a, b) => a - b);

  if (!financialData || !simulationData) {
    return null;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 350,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
    ...years.map((year) => ({
      title: year.toString(),
      dataIndex: year.toString(),
      key: year.toString(),
      width: 100,
    })),
  ];

  const getValuesForYears = (
    graphData: SimulationResultYearlyFinancialAnalysisData[],
    key: string
  ) => {
    const values: Record<string, string | number> = {};
    if (graphData) {
      years.forEach((year) => {
        const data = graphData.find((item) => item.year === year);
        values[year.toString()] = data
          ? converToMillion(data[key]) + ' $'
          : '0';
      });
    }
    return values;
  };

  financialData.graphData.forEach((item) => {
    const totalDepreciation = item.storageDepreciation + item.plantDepreciation;
    item.totalDepreciation = totalDepreciation;
  });

  const dataSource = [
    {
      key: '1',
      name: (
        <Typography.Text className="fs-14-bold text-gray-color">
          Revenues
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.totalRevenue) + ' $',
      ...getValuesForYears(financialData.graphData, 'totalRevenue'),
      children: [
        {
          key: '1-1',
          name: 'Energy Revenues',
          total:
            converToMillion(simulationData?.result.totalRevenueFromSale) + ' $',
          ...getValuesForYears(financialData.graphData, 'totalRevenueFromSale'),
          children: [
            {
              key: '1-1-1',
              name: 'Revenue from Discharge',
              total:
                converToMillion(simulationData?.result.revenueFromDischarge) +
                ' $',
              ...getValuesForYears(
                financialData.graphData,
                'revenueFromDischarge'
              ),
              children: [
                {
                  key: '1-1-1-1',
                  name: 'Revenue from Discharge-Plant',
                  total:
                    converToMillion(
                      simulationData?.result.revenueFromDischargePlant
                    ) + ' $',
                  ...getValuesForYears(
                    financialData.graphData,
                    'revenueFromDischargePlant'
                  ),
                },
                {
                  key: '1-1-1-2',
                  name: 'Revenue from Discharge-Purchase',
                  total:
                    converToMillion(
                      simulationData?.result.revenueFromDischargePurchased
                    ) + ' $',
                  ...getValuesForYears(
                    financialData.graphData,
                    'revenueFromDischargePurchased'
                  ),
                },
              ],
            },
            {
              key: '1-1-2',
              name: 'Revenue from Plant',
              total:
                converToMillion(simulationData?.result.revenueFromPlant) + ' $',
              ...getValuesForYears(financialData.graphData, 'revenueFromPlant'),
            },
          ],
        },
        {
          key: '1-2',
          name: 'Capacity Revenues',
          total:
            converToMillion(simulationData?.result.revenueFromOfferedCapacity) +
            ' $',
          ...getValuesForYears(
            financialData.graphData,
            'revenueFromOfferedCapacity'
          ),
          children: [
            {
              key: '1-2-1',
              name: 'Revenue from PFC',
              total:
                converToMillion(
                  simulationData?.result.revenueFromOfferedCapacityOfPfc
                ) + ' $',
              ...getValuesForYears(
                financialData.graphData,
                'revenueFromOfferedCapacityOfPfc'
              ),
            },
            {
              key: '1-2-2',
              name: 'Revenue from SFC',
              total:
                converToMillion(
                  simulationData?.result.revenueFromOfferedCapacityOfSfc
                ) + ' $',
              ...getValuesForYears(
                financialData.graphData,
                'revenueFromOfferedCapacityOfSfc'
              ),
            },
          ],
        },
      ],
    },
    {
      key: '2',
      name: 'Cost of Purchased Energy',
      total:
        converToMillion(simulationData?.result.costOfPurchasedEnergy) + ' $',
      ...getValuesForYears(financialData.graphData, 'costOfPurchasedEnergy'),
    },
    {
      key: '10',
      name: 'Pre-Plant Consumption Billing Cost',
      total:
        converToMillion(simulationData?.result.preplantConsumptionBillingCost) +
        ' $',
      ...getValuesForYears(
        financialData.graphData,
        'preplantConsumptionBillingCost'
      ),
    },
    {
      key: '3',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Gross Profit
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.grossProfit) + ' $',
      ...getValuesForYears(financialData.graphData, 'grossProfit'),
    },
    {
      key: '4',
      name: 'Operating Expenses',
      total: converToMillion(simulationData?.result.opexTotal) + ' $',
      ...getValuesForYears(financialData.graphData, 'opexTotal'),
      children: [
        {
          key: '4-1',
          name: 'Distribution Costs',
          total:
            converToMillion(simulationData?.result.distributionCosts) + ' $',
          ...getValuesForYears(financialData.graphData, 'distributionCosts'),
          children: [
            {
              key: '4-1-1',
              name: 'Distribution Cost of Purchased Energy',
              total:
                converToMillion(
                  simulationData?.result.distributionCostOfPurchasedEnergy
                ) + ' $',
              ...getValuesForYears(
                financialData.graphData,
                'distributionCostOfPurchasedEnergy'
              ),
              children: [
                {
                  key: '4-1-1-1',
                  name: 'Distribution Cost of Purchased Energy for Consumption',
                  total:
                    converToMillion(
                      simulationData?.result
                        .distributionCostOfPurchasedEnergyForConsumption
                    ) + ' $',
                  ...getValuesForYears(
                    financialData.graphData,
                    'distributionCostOfPurchasedEnergyForConsumption'
                  ),
                },
                {
                  key: '4-1-1-2',
                  name: 'Distribution Cost of Purchased Energy for Arbitrage',
                  total:
                    converToMillion(
                      simulationData?.result
                        .distributionCostOfPurchasedEnergyForArbitrage
                    ) + ' $',
                  ...getValuesForYears(
                    financialData.graphData,
                    'distributionCostOfPurchasedEnergyForArbitrage'
                  ),
                },
              ],
            },
            {
              key: '4-1-2',
              name: 'Distribution Cost of Sold Energy',
              total:
                converToMillion(
                  simulationData?.result.distributionCostOfSoldEnergy
                ) + ' $',
              ...getValuesForYears(
                financialData.graphData,
                'distributionCostOfSoldEnergy'
              ),
              children: [
                {
                  key: '4-1-2-1',
                  name: 'Distribution Cost of Sold Energy from Plant',
                  total:
                    converToMillion(
                      simulationData?.result
                        .distributionCostOfSoldEnergyDirectlyFromPlant
                    ) + ' $',
                  ...getValuesForYears(
                    financialData.graphData,
                    'distributionCostOfSoldEnergyDirectlyFromPlant'
                  ),
                },
                {
                  key: '4-1-2-2',
                  name: 'Distribution Cost of Sold Energy from Storage',
                  total:
                    converToMillion(
                      simulationData?.result
                        .distributionCostOfSoldEnergyFromStorage
                    ) + ' $',
                  ...getValuesForYears(
                    financialData.graphData,
                    'distributionCostOfSoldEnergyFromStorage'
                  ),
                },
              ],
            },
          ],
        },
        {
          key: '4-2',
          name: 'Storage System Fixed OPEX',
          total:
            converToMillion(simulationData?.result.storageSystemFixedOpex) +
            ' $',
          ...getValuesForYears(
            financialData.graphData,
            'storageSystemFixedOpex'
          ),
        },
        {
          key: '4-3',
          name: 'Power Plant Fixed OPEX',
          total:
            converToMillion(simulationData?.result.powerPlantFixedOpex) + ' $',
          ...getValuesForYears(financialData.graphData, 'powerPlantFixedOpex'),
        },
      ],
    },
    {
      key: '11',
      name: 'Pre-Plant Distribution Cost of Purchased Energy',
      total:
        converToMillion(
          simulationData?.result.preplantDistributionCostOfPurchasedEnergy
        ) + ' $',
      ...getValuesForYears(
        financialData.graphData,
        'preplantDistributionCostOfPurchasedEnergy'
      ),
    },
    {
      key: '5',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          EBITDA
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.ebitda) + ' $',
      ...getValuesForYears(financialData.graphData, 'ebitda'),
    },
    {
      key: '6',
      name: 'Depreciation',

      ...getValuesForYears(financialData.graphData, 'totalDepreciation'),

      children: [
        {
          key: '6-1',
          name: 'Storage System Depreciation',

          ...getValuesForYears(financialData.graphData, 'storageDepreciation'),
        },
        {
          key: '6-2',
          name: 'Power Plant Depreciation',

          ...getValuesForYears(financialData.graphData, 'plantDepreciation'),
        },
      ],
    },
    {
      key: '7',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          EBIT
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.ebit) + ' $',
      ...getValuesForYears(financialData.graphData, 'ebit'),
    },
    {
      key: '12',
      name: 'Interest Expense',

      total:
        converToMillion(simulationData?.result.totalInterestExpense) + ' $',
      ...getValuesForYears(financialData.graphData, 'totalInterestExpense'),
    },
    {
      key: '8',
      name: 'Taxes',
      total: converToMillion(simulationData?.result.tax) + ' $',
      ...getValuesForYears(financialData.graphData, 'tax'),
    },
    {
      key: '9',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Net Income
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.netIncome) + ' $',
      ...getValuesForYears(financialData.graphData, 'netIncome'),
    },
  ];

  return (
    <StyledProfit>
      <Table
        dataSource={dataSource}
        columns={columns}
        expandable={{
          expandedRowKeys,
          onExpand,
          expandIcon: customExpandIcon,
          rowExpandable: (record) => !!record.children,
        }}
        pagination={false}
        scroll={{ x: scrollX }}
      />
    </StyledProfit>
  );
};

export default ProfitAndLossTable;

import { useMemo } from 'react';

import { SimulationResultYearlyFinancialAnalysisData } from '@/types/simulations';

interface CalculationResult {
  calculatedNPV: number;
  calculatedPaybackPeriod: number;
  yearlyNPVs: Record<string, string>;
}

const calculateNPV = (
  cashFlows: number[],
  indexes: number[],
  hurdleRate: number
): number => {
  return cashFlows.reduce((npv, cashFlow, i) => {
    const rate = hurdleRate / 100;
    return npv + cashFlow / Math.pow(1 + rate, indexes[i]);
  }, 0);
};

const calculateYearlyNPVs = (
  graphData: SimulationResultYearlyFinancialAnalysisData[],
  cashFlows: number[],
  hurdleRate: number
): Record<string, string> => {
  const values: Record<string, string> = {};
  const years = graphData.map((item) => item.year);
  const minYear = Math.min(...years);
  const rate = hurdleRate / 100;

  years.forEach((year, index) => {
    const cashFlow = cashFlows[index];
    if (cashFlow !== undefined) {
      const yearIndex = year - minYear;
      const discountedValue = cashFlow / Math.pow(1 + rate, yearIndex);
      values[year.toString()] = discountedValue.toString();
    }
  });

  return values;
};

const calculateDiscountedPaybackPeriod = (yearlyNPVs: number[]): number => {
  let cumulativeNPV = 0;
  let previousCumulativeNPV = 0;

  for (let year = 0; year < yearlyNPVs.length; year++) {
    previousCumulativeNPV = cumulativeNPV;
    cumulativeNPV += yearlyNPVs[year];

    if (cumulativeNPV >= 0) {
      if (year === 0) return 0;

      const fraction =
        Math.abs(previousCumulativeNPV) /
        (Math.abs(previousCumulativeNPV) + Math.abs(yearlyNPVs[year]));
      return year - 1 + fraction;
    }
  }

  return yearlyNPVs.length;
};

const useCalculatedNPVandPayback = (
  graphData: SimulationResultYearlyFinancialAnalysisData[] | undefined,
  cashFlows: number[] | undefined,
  hurdleRate: number,
  defaultHurdleRate: number,
  defaultNPV?: number,
  defaultPaybackPeriod?: number
): CalculationResult => {
  return useMemo(() => {
    if (!graphData || !cashFlows || hurdleRate === defaultHurdleRate) {
      return {
        calculatedNPV: defaultNPV || 0,
        calculatedPaybackPeriod: defaultPaybackPeriod || 0,
        yearlyNPVs: {},
      };
    }

    const years = [...new Set(graphData.map((item) => item.year))].sort(
      (a, b) => a - b
    );
    const minYear = Math.min(...years);
    const indexes = years.map((year) => year - minYear + 1);

    // Calculate yearly NPVs using cashFlows
    const yearlyNPVs = calculateYearlyNPVs(graphData, cashFlows, hurdleRate);
    const yearlyNPVArray = Object.values(yearlyNPVs).map(Number);

    // Calculate total NPV from cash flows
    const npv = calculateNPV(cashFlows, indexes, hurdleRate);

    // Calculate payback period using yearly NPVs
    const paybackPeriod = calculateDiscountedPaybackPeriod(yearlyNPVArray);

    return {
      calculatedNPV: npv,
      calculatedPaybackPeriod: paybackPeriod,
      yearlyNPVs,
    };
  }, [
    graphData,
    cashFlows,
    hurdleRate,
    defaultHurdleRate,
    defaultNPV,
    defaultPaybackPeriod,
  ]);
};

export default useCalculatedNPVandPayback;
